/* eslint-disable react/jsx-props-no-spreading */
import { Button, Form, Input, message, notification } from 'antd';
// core components
import colors from 'constants/colors';
import settings from 'constants/settings';
import { generateSHA1 } from 'helpers/CryptoHelper';
import { amesSendMail, query } from 'helpers/QueryHelper';
import i18next from 'i18next';
import { init, login } from 'modules/Auth/actions';
import { GenPasswordModel } from 'modules/Presentation/Viewer/components/Model';
import moment from 'moment';
import getSettings from 'pages/Administration/api/getSettings';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { Card, CardBody, CardHeader, Col, Container, Modal, Row } from 'reactstrap';
import RegisterStudentPopup from '../RegisterStudentPopup';

const FormItem = Form.Item;

const VimaruLoginPopup = () => {
  const { t } = useTranslation('auth');
  const history = useHistory();
  const dispatch = useDispatch();
  const [isStatusFormForgotPassword, setStatusFormForgotPassword] = React.useState(false);
  const [modalStudentVisible, setModalStudentVisible] = React.useState(false);
  const [isProcessing, setIsProcessing] = React.useState(false);
  const [isDisabledButton, setIsDisabledButton] = React.useState(false);

  const loggedInUser = useSelector((userState) => userState.authReducer.loggedInUser);
  const ConfigSettings = useSelector((state) => state.settingsReducer.configs);
  const mailer =
    ConfigSettings?.find((x) => x?.key.toUpperCase() === 'MAILER')?.value ?? 'AMES ENGLISH <mailer@ames.edu.vn>';
  const templateResetPassword =
    ConfigSettings?.find((x) => x?.key === 'TEMPLATE_RESETPASSWORD')?.value ?? 'AMES_Mail_ResetPassword';

  React.useEffect(() => {
    if (loggedInUser) {
      history.push(`/${settings?.PATHNAME}/home`);
    } else {
      dispatch(init());
    }
  }, [dispatch, history, loggedInUser]);

  const [form] = Form.useForm();
  const handleSubmit = (values) => {
    const { username, password } = values;
    dispatch(login(username, password));
  };

  const handleForgotPassword = (e) => {
    e.preventDefault();
    setStatusFormForgotPassword(true);
  };
  const handleCancelFormForgotPassword = (e) => {
    e.preventDefault();
    setStatusFormForgotPassword(false);
  };
  const [formForgotPassword] = Form.useForm();

  const sendForgotPassword = async (values) => {
    setIsProcessing(true);
    const res = await query('p_VIMARU_User_SearchByEmail', { Email: values.Email });

    if (res?.[0]?.studentId) {
      const { fullName, email, message: messageResult } = res[0];
      if (fullName || email) {
        const GenPassword = GenPasswordModel(2, 6);
        const parameters = {
          Email: email,
          Password: generateSHA1(GenPassword),
        };
        await onUpdatePassword(parameters);
        const dateNo = moment().format('YYYYMMDDhhmm');
        const dataSendMail = {
          to: email.toLowerCase(),
          isSendFast: true,
          moduleId: settings.MODULE_ID,
          cc: '',
          bcc: '',
          subject: `Quên mật khẩu #${dateNo}`,
          body: '',
          bodyHTML: '',
          attachment: '',
          templateName: templateResetPassword,
          sourceInfo: `API Save Email - ${templateResetPassword}`,
          templateData: JSON.stringify({
            email: email,
            password: GenPassword,
            dateNo: dateNo,
            studentName: fullName,
          }),
          mailer: mailer,
        };
        await amesSendMail('saveEmail', dataSendMail).then((res) => {
          notification.success({ message: `${i18next.t('Password has been sent to email')}: ${email}`, duration: 5 });
          setIsDisabledButton(true);
        });
      } else {
        notification.error({ message: i18next.t(messageResult) });
        if (messageResult.includes('chưa xác thực')) {
          notification.error({
            message: messageResult,
            description: 'Bạn sẽ chuyển tới trang xác thực trong vòng 3s',
            duration: 0,
          });
          setTimeout(() => {
            window.location.href = `/verify?email=${encodeURIComponent(values.Email)}`;
          }, 3000);
          notification.error({
            message: messageResult,
            description: i18next.t('auth:You will be redirected to the verification page within 3 seconds'),
            duration: 0,
          });
          setTimeout(() => {
            window.location.href = `/verify?email=${encodeURIComponent(values.Email)}`;
          }, 3000);
        }
      }
    } else {
      message.warning(i18next.t('Email does not exist'));
    }
    setIsProcessing(false);
  };
  const onUpdatePassword = async (parameters) => {
    await query('p_VIMARU_UserPassword_RESET', parameters);
  };
  return (
    <React.Fragment>
      {!isStatusFormForgotPassword ? (
        <Container className='transition__form__login'>
          <Row className='justify-content-center'>
            <Col lg='12' md='12'>
              <Card className='bg-secondary border-0 mb-0'>
                <CardHeader className='bg-transparent pb-3'>
                  <div className='text-center mt-2 mb-1'>
                    <span
                      style={{
                        color: colors.font,
                        fontSize: 24,
                        fontWeight: 600,
                        textTransform: 'uppercase',
                      }}
                    >
                      {t('login')}
                    </span>
                  </div>
                  {/* <div className='text-center'>
                    <small>
                      <span>Sử dụng tài khoản </span>
                      <a href='https://englishcity.vn'>ENGLISH CITY</a>
                      <span> để đăng nhập</span>
                    </small>
                  </div> */}
                </CardHeader>
                <CardBody className='px-lg-5 py-lg-5'>
                  <Form autoComplete='on' form={form} onFinish={handleSubmit}>
                    <FormItem
                      hasFeedback
                      name='username'
                      rules={[
                        {
                          message: i18next.t('invalidEmail'),
                          type: 'email',
                        },
                        {
                          message: i18next.t('Please no newline'),
                          whitespace: true,
                        },
                        {
                          message: i18next.t('Please enter email'),
                          required: true,
                        },
                      ]}
                      maxLength={250}
                    >
                      <Input
                        style={{
                          borderRadius: 3,
                          boxShadow: '0 1px 3px rgba(50, 50, 93, 0.15), 0 1px 0 rgba(0, 0, 0, 0.02)',
                        }}
                        allowClear
                        size='large'
                        prefix={<i style={{ color: 'rgba(0,0,0,.25)' }} className='fas fa-user' />}
                        placeholder='Username (*)'
                      />
                    </FormItem>

                    <FormItem
                      hasFeedback
                      name='password'
                      rules={[
                        // { min: 6, message: "Tối thiểu 6 ký tự!" },

                        {
                          message: i18next.t('Please no newline'),
                          whitespace: true,
                        },
                        {
                          message: i18next.t('please enter', { field: i18next.t('password') }),
                          required: true,
                        },
                      ]}
                    >
                      <Input.Password
                        style={{
                          borderRadius: 3,
                          boxShadow: '0 1px 3px rgba(50, 50, 93, 0.15), 0 1px 0 rgba(0, 0, 0, 0.02)',
                        }}
                        allowClear
                        size='large'
                        prefix={<i style={{ color: 'rgba(0,0,0,.25)' }} className='fas fa-lock' />}
                        placeholder={i18next.t('auth:Password') + '(*)'}
                      />
                    </FormItem>

                    <div className='text-center'>
                      <Button className='mt-2' type='primary' htmlType='submit' size='large' block>
                        {i18next.t('auth:login')}
                      </Button>

                      {/* <Button
                        className='mt-2'
                        type='primary'
                        block
                        danger
                        htmlType='button'
                        size='large'
                        onClick={() => {
                          setModalStudentVisible(true);
                          // window.open('https://hanhtrangso.nxbgd.vn/', '_blank');
                        }}
                      >
                        Đăng ký
                      </Button> */}
                    </div>
                  </Form>
                  <div
                    className='text-light'
                    style={{ textAlign: 'end', padding: '5px 0', fontStyle: 'italic', fontWeight: 500 }}
                    href='#pablo'
                    onClick={(e) => handleForgotPassword(e)}
                  >
                    <span> {i18next.t('forgot_password')}?</span>
                  </div>
                  {/* <Row className='mt-2'>
                   <Col sm='5'>
                     <span style={{ cursor: 'pointer' }} className='text-black' onClick={toggleModal}>
                       <small style={{ fontSize: 15 }}>Quên mật khẩu?</small>
                     </span>
                     <ForgotPasswordModal toggleModal={toggleModal} isVisibled={state.isVisibled} />
                   </Col>
                 </Row> */}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      ) : (
        <Container className='transition__form__login'>
          <Row className='justify-content-center'>
            <Col lg='12' md='12'>
              <Card className='bg-secondary border-0 mb-0'>
                <CardHeader className='bg-transparent pb-3'>
                  <div className='text-center mt-2 mb-1'>
                    <span
                      style={{
                        color: colors.font,
                        fontSize: 24,
                        fontWeight: 600,
                      }}
                    >
                      {i18next.t('auth:forgot_password').toUpperCase()}
                    </span>
                  </div>
                  {/* <div className='text-center'>
                    <small>
                      <span>Sử dụng tài khoản </span>
                      <a href='https://englishcity.vn'>ENGLISH CITY</a>
                      <span> để đăng nhập</span>
                    </small>
                  </div> */}
                </CardHeader>
                <CardBody className='px-lg-5 py-lg-5'>
                  <Form autoComplete='on' form={formForgotPassword} onFinish={sendForgotPassword}>
                    <FormItem
                      hasFeedback
                      name='Email'
                      rules={[
                        {
                          message: i18next.t('invalidEmail'),
                          type: 'email',
                        },
                        {
                          message: i18next.t('Please no newline'),
                          whitespace: true,
                        },
                        {
                          message: i18next.t('please enter', { field: 'email' }),
                          required: true,
                        },
                      ]}
                      maxLength={250}
                    >
                      <Input
                        style={{
                          borderRadius: 3,
                          boxShadow: '0 1px 3px rgba(50, 50, 93, 0.15), 0 1px 0 rgba(0, 0, 0, 0.02)',
                        }}
                        allowClear
                        size='large'
                        prefix={<i style={{ color: 'rgba(0,0,0,.25)' }} className='fas fa-user' />}
                        placeholder='Username (*)'
                      />
                    </FormItem>

                    <div className='text-center'>
                      <Button
                        className='mt-2'
                        type='primary'
                        htmlType='submit'
                        size='large'
                        block
                        loading={isProcessing}
                        disabled={isDisabledButton}
                      >
                        {i18next.t('Send')}
                      </Button>

                      <Button
                        className='mt-2'
                        // type='primary'
                        block
                        // danger
                        htmlType='button'
                        size='large'
                        onClick={(e) => {
                          handleCancelFormForgotPassword(e);
                          // window.open('https://hanhtrangso.nxbgd.vn/', '_blank');
                        }}
                      >
                        {i18next.t('Back to login')}
                      </Button>
                    </div>
                  </Form>
                  {/* <Row className='mt-2'>
                <Col sm='5'>
                  <span style={{ cursor: 'pointer' }} className='text-black' onClick={toggleModal}>
                    <small style={{ fontSize: 15 }}>Quên mật khẩu?</small>
                  </span>
                  <ForgotPasswordModal toggleModal={toggleModal} isVisibled={state.isVisibled} />
                </Col>
              </Row> */}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      )}

      <Modal
        isOpen={modalStudentVisible}
        centered
        backdrop='static'
        backdropClassName='sachso-modal-backdrop'
        size='ms'
      >
        <RegisterStudentPopup
          onClose={() => {
            setModalStudentVisible(false);
          }}
        />
      </Modal>
    </React.Fragment>
  );
};

VimaruLoginPopup.propTypes = {
  // history: PropTypes.instanceOf(Object).isRequired,
};
export default VimaruLoginPopup;
