import { Button, Modal } from 'antd';
import { get, query } from 'helpers/QueryHelper';
import ExcercisePage from 'modules/WorkSheet/CanvasToolPlus/ExcercisePage';
import React from 'react';
import { useQuery } from 'react-query';
import QuizDetailPreview from './QuizDetailPreview';

const getResults = (logId, slideId, studentId, type) => {
  return query('p_VIMARU_GetAnswerLogs_ByStudentId_Details_Lev2', { logId, slideId, studentId, type });
};

const getSlide = (id) => {
  const entityName = 'Slides';
  const fields = 'jsonData';
  const parameters = { id };
  const sort = ['CreatedDate DESC'].join(',');
  const subModuleName = '';
  const moduleName = 'SACHSO';
  return get(entityName, fields, parameters, sort, subModuleName, moduleName);
};

export default function SlideDetailPreview({ record }) {
  const [visible, setVisible] = React.useState(false);

  const { data, isLoading } = useQuery(
    ['GET_RESULTS_DATA', record?.slideId, record?.id],
    () => getResults(record?.id, record?.slideId, record?.studentId, record?.type),
    { enabled: visible },
  );

  //Vì record chỉ chứa slideId mà lấy bài WS cần workSheetId
  const { data: workSheetId } = useQuery(
    ['GET_WORKSHEET_ID', record?.slideId, record?.id],
    () =>
      new Promise(async (resolve) => {
        const res = await getSlide(record?.slideId);
        return resolve(res?.[0]?.jsonData?.workSheetId);
      }),
    { enabled: visible && record?.type === 'WorkSheet' },
  );

  //Hiện tại chỉ có xử lý hiển thị cho 2 type
  if (['WorkSheet', 'Quiz'].includes(record?.type)) {
    return (
      <>
        <Button type='text' style={{ color: '#006EE6' }} onClick={() => setVisible(true)}>
          Xem trước
        </Button>
        <Modal
          destroyOnClose
          centered
          width='90vw'
          bodyStyle={{ height: '80vh', overflow: 'auto' }}
          open={visible}
          title='Chi tiết lần làm'
          onCancel={() => setVisible(false)}
          footer={[
            <Button
              type='primary'
              key='close-modal'
              onClick={() => {
                setVisible(false);
              }}
            >
              Đóng
            </Button>,
          ]}
        >
          {record?.type === 'WorkSheet' && (
            <ExcercisePage
              typeView={'view'}
              userAnswer={data?.[0]?.jsonData}
              loadingAnswer={false}
              isHiddenShowColor={false}
              idCurrent={workSheetId}
            />
          )}
          {record?.type === 'Quiz' && <QuizDetailPreview data={data} />}
        </Modal>
      </>
    );
  }

  return <div></div>;
}
